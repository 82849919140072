.navigation {
  z-index: 1000;
  background: #fff;
  border-right: 1px solid #edf0f5;
}

.logo {
  height: 80px;
  width: 100%;
  margin: 0 16px 10px 16px;
  padding-right: 8px;
}

.logo img {
  margin: 0 auto 40px;
  display: block;
  height: 100px;
  width: 90%;
}
